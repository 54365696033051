<template>
  <div>
    <span class="d-inline-block h3 mb-0"
      >Equipe :
      <span style="font-weight: 600 !important"> {{ name }}</span></span
    >
    {{ existedCollaborators }}
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche collaborateur:</span>
      <v-select
        class="w-100"
        placeholder="Nom"
        :options="responsables"
        v-model="selectedUser"
        :reduce="(responsable) => responsable.value"
        label="text"
      >
        <template #no-options> Liste vide </template>
      </v-select>

      <b-button
        class="ml-1"
        @click="addCollaborators"
        :disabled="selectedGuest === null"
        type=""
      >
        Confirmer
      </b-button>
    </div>
    <!-- {{ organisation }} -->
    <b-table
      :fields="fields"
      :items="filteredUsers"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(selection)="data">
        <div class="d-flex justify-content-center">
          <b-form-radio
            v-model="selectedGuest"
            :value="data.item.id"
          ></b-form-radio>
        </div>
      </template>
      <template #cell(firstname)="data">
        <span> {{ data.item.firstname + " " + data.item.lastname }}</span>
      </template>
      <template #cell(organisationRatachement)="data">
        <span> {{ data.value ? data.value.libelle : "-" }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      data: [],
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedGuest: null,
      selectedUser: null,
      loading: true,
      organisation: null,
      fields: [
        { key: "firstname", label: "Nom et prénom" },
        // { key: "organisationRatachement", label: "Organisation" },
        {
          key: "selection",
          label: "Sélection",
          thStyle: { textAlign: "center" },
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch("users/fetchAllResponsables")
      .then(() => (this.loading = false));
    this.$store.dispatch("status/fetchFdrStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["FDR_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("type", ["FDR_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      })).filter(
        (user) =>
          !this.existedCollaborators.find(
            (existedUser) => existedUser.id == user.value
          )
      );
    },
    statusFdr() {
      return this.FDR_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation.id,
        text: organisation.libelle,
      }));
    },
    fdrTypes() {
      return this.FDR_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine.id,
        text: patrimoine.name,
      }));
    },
    filteredUsers() {
      return this.selectedUser
        ? this.RESPONSABLES.filter(
            (user) =>
              user.id === this.selectedUser &&
              !this.existedCollaborators.find(
                (existedUser) => existedUser.id == user.id
              )
          )
        : this.RESPONSABLES.filter(
            (user) =>
              !this.existedCollaborators.find(
                (existedUser) => existedUser.id == user.id
              )
          );
    },

    entityList() {
      return this.ENTITIES.map((entite) => ({
        value: `api/referentiel/entites/${entite.id}`,
        text: entite.libelle,
      }));
    },
    rows() {
      return this.filteredUsers.length;
    },
  },
  methods: {
    addCollaborators() {
      this.collaborateurs = this.collaborateurs.map((data) => data["@id"]);
      this.collaborateurs.push("api/users/" + this.selectedGuest);
      var item = {
        id: this.$route.params.id,
        members: this.collaborateurs,
      };
      this.$store.dispatch("teams/updateTeam", item).then(() => {
        this.collaborateurs = "";
        Swal.fire("Le collaborateur est bien créée !", "", "success");
        this.$emit("close");
      });
    },
  },
  props: {
    collaborateurs: Array,
    name: String,
    existedCollaborators: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
