<template>
  <div>
    <b-card>
      <modal-actions @close="$router.push({ name: 'organisations' })" />

      <div class="text-secondary">
        <p class="h2">Modifier équipe n°1</p>
        <hr />
      </div>

      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <p class="text-secondary h3">
            <i class="mdi mdi-apps"></i> Identité de l'équipe: équipe 1
          </p>
        </div>

        <div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="Libellé équipe"
                label-class="font-weight-bold"
              >
                <b-form-input type="text"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="Responsable"
                label-class="font-weight-bold"
              >
                <b-form-select :options="['Steve EWORE']"></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="Organisation de rattachement"
                label-class="font-weight-bold"
              >
                <b-form-input type="text"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-12 col-md-6">
              <b-form-group label-cols="12" label="Description" label-class="font-weight-bold">
                <b-form-textarea
                  placeholder="Description..."
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-12 d-flex justify-content-end">
          <b-button
            @click="addCollaborators = !addCollaborators"
            class="align-btn-table"
            ><i class="mdi mdi-plus"></i> Ajouter un collaborateur</b-button
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            :items="collaborators.tableItems"
            :fields="collaborators.tableFields"
            responsive
          >
            <template v-slot:cell(actions)="data">
              <table-actions
                class="justify-content-center"
                :actions="data.value"
              />
            </template>
          </b-table>
        </div>
      </div>
    </b-card>

    
    <div v-if="addCollaborators" to="modals-xyz-548">
      <Modal @close="addCollaborators = !addCollaborators">
        <add-collaborators />
      </Modal>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import TableActions from "@/components/custom/TableActions.vue";
import Modal from '@/components/custom/Modal.vue';
import AddCollaborators from '../components/AddCollaborators.vue';

export default {
  components: {
    ModalActions,
    TableActions,
    Modal,
    AddCollaborators
  },
  data: () => ({
    editMode: false,
    addCollaborators: false,
    collaborators: {
      tableFields: [
        { key: "full_name", label: "Nom complet", sortable: true },
        { key: "mail", label: "Mail", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        { key: "account", label: "Compte", sortable: true },
        { key: "actions", label: "" },
      ],
      tableItems: [
        {
          full_name: "Dominick Zboncak",
          mail: "Jean46@example.com",
          organisation_attachment: "Cadrage",
          function: "Lorem",
          account: "Actif",
          actions: ["edit"],
        },
        {
          full_name: "Colin Satterfield",
          mail: "Dasia.Shields61@example.com",
          organisation_attachment: "Cadrage",
          function: "Ipsum",
          account: "Actif",
          actions: ["edit"],
        },
        {
          full_name: "Simon Wehner",
          mail: "Otto86@example.org",
          organisation_attachment: "Cadrage",
          function: "Ipsum",
          account: "Actif",
          actions: ["edit"],
        },
        {
          full_name: "Tim Howell",
          mail: "Mckayla.Kautzer@example.org",
          organisation_attachment: "Cadrage",
          function: "Ipsum",
          account: "Actif",
          actions: ["edit"],
        },
        {
          full_name: "Cathy McDermott",
          mail: "Kelley14@yahoo.com",
          organisation_attachment: "Cadrage",
          function: "Lorem",
          account: "Actif",
          actions: ["edit"],
        },
      ],
    },
  }),
};
</script>

<style></style>
